import {
  type Control,
  Controller,
  type UseFormRegister,
  type UseFormWatch,
} from 'react-hook-form';

import { type Media } from '@lp-lib/media';

import { type GamePack } from '../../../types/game';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { SwitcherControlled } from '../../Switcher';
import { Featured, PromotionalAssets, TeamRandomization } from './Shared';
import { type GamePackEditorFormData } from './types';

function Replayable(props: GamePackEditorAdvancedProps) {
  return (
    <div className='flex w-2/3 justify-between items-center'>
      <p className='mr-4 text-xs font-light'>Replayable</p>
      <Controller
        name='replayable'
        control={props.control}
        render={({ field }) => (
          <SwitcherControlled
            name={field.name}
            checked={field.value || false}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
}

interface GamePackEditorAdvancedProps {
  isOpen: boolean;
  onToggle: () => void;

  packIsPrime: boolean;
  pack: GamePack | null | undefined;
  control: Control<GamePackEditorFormData>;
  watch: UseFormWatch<GamePackEditorFormData>;
  register: UseFormRegister<GamePackEditorFormData>;
  cover: Media | null;
  onShowOverlay: (overlay: JSX.Element | null) => void;
}

export function GamePackEditorAdvanced(
  props: GamePackEditorAdvancedProps
): JSX.Element | null {
  return (
    <div className='mt-4'>
      <button
        type='button'
        className='btn flex justify-center items-center'
        onClick={props.onToggle}
      >
        <p className='mr-1 font-medium text-sms'>Advanced</p>
        {props.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>

      {props.isOpen && (
        <div className='mt-3 flex flex-col justify-start items-start gap-2'>
          {props.packIsPrime && <Featured {...props} />}
          {props.packIsPrime && props.pack && (
            <PromotionalAssets {...props} pack={props.pack} />
          )}
          <Replayable {...props} />
          <TeamRandomization {...props} icebreakerEnabled className='mt-7.5' />
        </div>
      )}
    </div>
  );
}
